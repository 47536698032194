import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {SetLicense} from "../redux/actions/app.actions";
import {licenseSelector} from "../redux/selectors";

export const useGetLicense = () => {

    const license = useSelector(licenseSelector);
    const dispatch = useDispatch();

    useEffect( () => {

        if ( license.length === 0 ) {

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString); // doesn't work in IE, but who cares ;)
            const licenseFromParam = urlParams.get("license");
            if ( licenseFromParam && licenseFromParam.length > 0 ) {
                dispatch(SetLicense(licenseFromParam));
            }

        }

    },[license, dispatch]);

};