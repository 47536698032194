import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router"
import { createRootReducer } from "./reducers";

export const history = createBrowserHistory();

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

export default function configureStore(preloadedState) {
    const store = createStore( 
      createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
//      composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
            ),
        ),
    );

    return store
}