import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./i18n";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import configureStore, {history} from "./redux/configureStore";
import {SimpleChatAdapter} from "@chatscope/simple-chat-adapter";
import { nanoid } from "nanoid";
import {AutoDraft, BasicStorage, ChatProvider  } from "@chatscope/use-chat";
import type {ChatMessage, MessageContentType, IStorage} from "@chatscope/use-chat";

const groupIdGenerator = () => nanoid();

const messageIdGenerator = <T extends MessageContentType>(message: ChatMessage<T>) => nanoid();

const serviceFactory = (storage:IStorage, updateState: () => void) =>
    new SimpleChatAdapter(storage, updateState);

const externalStorage = new BasicStorage({
    groupIdGenerator,
    messageIdGenerator
});

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ChatProvider serviceFactory={serviceFactory} storage={externalStorage} config={{
        typingThrottleTime: 250,
        typingDebounceTime: 900,
        debounceTyping: true,
        autoDraft: AutoDraft.Save | AutoDraft.Restore
      }}>
        <App />
      </ChatProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
