import {ComponentType, EventHandler, SyntheticEvent} from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import "./Widget.css"
import {useTranslation} from "react-i18next";

const Widget = ({component:Page, onMinimize}:{component:ComponentType, onMinimize:EventHandler<SyntheticEvent>}) => {
  
    const {t} = useTranslation("Widget");
    
    return (
      <div className="cw-widget">
        <div className="container-fluid d-flex flex-column position-absolute bg-white" style={{left:0, right:0, top:0, bottom:0}}>
          <div className="row bg-light">
            <div className="col-12">
              <Header onMinimize={onMinimize} title={`${t("title")}`}/>
            </div>
          </div>
          <div className="row flex-grow-1 bg-">
            <div className="col-12">
              <Page />
            </div>
          </div>
          <div className="row bg-light">
            <div className="col-12">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
    
};

Widget.propTypes = {
    onMinimize: PropTypes.func
};

Widget.defaultProps = {
  onMinimize: (evt:SyntheticEvent) => {}
};

export default Widget;