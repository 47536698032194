import {lazy, Suspense} from 'react';
import './App.css';
import {Route, Switch, Redirect} from "react-router";
import WidgetWindow from "./components/WidgetWindow";
import { Loading } from "./components";
import { useInitializeApp } from "./hooks"; 

const ChatContainer = lazy(() => import("./containers/ChatContainer"));
const MessageContainer = lazy(() => import("./containers/ContactFormContainer"));
const SettingsContainer = lazy(() => import("./containers/SettingsContainer"));

const serverUrl = process.env.REACT_APP_SERVER_URL ?? "";
const apiUrl = process.env.REACT_APP_API_URL ?? "";

const App = () => {
  
  const initialized = useInitializeApp(serverUrl, apiUrl);
  
   if ( initialized ) {
    return (
      <Suspense fallback={<Loading />}>
            <div>
              <Switch>
                <Route exact path="/">
                  <WidgetWindow component={ChatContainer}/>
                </Route>

                <Route path="/message-form" >
                  <WidgetWindow component={MessageContainer}/>
                </Route>

                <Route path="/settings">
                  <WidgetWindow component={SettingsContainer}/>
                </Route>

                <Route path="*">
                  <Redirect to="/"/>
                </Route>
              </Switch>
            </div>
      </Suspense>);
   } else {
     return null;
   }    
};

export default App;
