import { createReducer } from "@reduxjs/toolkit";
import { SetLicense } from "../actions/app.actions";

const initialState = {
  license: ""
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(SetLicense, (state, action)=> {
    state.license = action.payload;
    return state;
  }).addDefaultCase((state, action) => state);
});