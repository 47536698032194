import { SyntheticEvent} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots} from "@fortawesome/free-solid-svg-icons";
import "./DefaultChatIcon.css";

const DefaultChatIcon = ({onClick}:{onClick: (evt:SyntheticEvent) => void}) => {
    
    return (
      <div className="d-flex justify-content-center align-items-center position-absolute cw-c-pointer" style={
        { width:"90px",
          height:"90px",
          bottom:0,
          right:0
        }
      }>
        <div className="d-flex justify-content-center align-items-center default-chat-icon" onClick={onClick}>
          <FontAwesomeIcon icon={faCommentDots} flip="horizontal" size="2x"/>
        </div>
      </div>
    );
    
};

DefaultChatIcon.propTypes = {
    onClick: PropTypes.func
};

DefaultChatIcon.defaultProps = {
  onClick: (evt:SyntheticEvent) => {}
};
export default DefaultChatIcon;