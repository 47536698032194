import {ComponentType, useEffect, useState} from "react";
import DefaultChatIcon from "./DefaultChatIcon/DefaultChatIcon";
import Widget from "./Widget/Widget";
import {useGetMediaQueryFromLoader} from "../hooks/useGetMediaQueryFromLoader";

interface StyleDef {
  [key:string]: string | number
}

// TODO: Skąd brać ten origin? Czy on nie powinien być jakoś przekazany w paraemtrze?
// A moze powinien na podstawie licencji być pobierany z serwera? Chyba tak, bo wtedy jest origin przypisany do licencji i tyle, musi serwer o tym wiedzieć
//const targetOrigin = "http://localhost:3000";
// Origin, z którego został załadowany widget (strona hostująca)
//const targetOrigin = "http://localhost";

// Dla produkcji
//const targetOrigin = "https://sample.chatscope.io";
const targetOrigin = "*";

const host = window.parent;

const getMaximizedStyles = (mobile:boolean):StyleDef => {
  const styles:StyleDef = {
    display: "block"
  };
  if ( mobile ) {
    styles.width = "100%";
    styles.height = "100%";
    styles.top = "0";
    styles.bottom = "0";
    styles.right = "0";
    styles.left = "0";
    styles.maxHeight = "none";
  } else {
    styles.width = "400px";
    styles.height = "647px";
    styles.right = "40px";
    styles.bottom = "40px";
    styles.top = "auto";
    styles.left = "auto";
    styles.maxHeight = "calc(100% - 40px)"; // 40px must be equal with bottom
  }
  return styles;
}

const getMinimizedStyles = (mobile:boolean):StyleDef => {
  const styles:StyleDef = {
    width: "90px",
    height: "90px",
    display: "block",
    maxHeight: "90px"
  };
  if ( mobile ) {
    styles.bottom = "10px";
    styles.right = "10px"
    styles.top = "auto";
    styles.left = "auto";
  } else {
    styles.top = "auto";
    styles.bottom = "40px";
    styles.right = "40px";
    styles.left = "auto";
  }
  return styles;
}

// TODO: To powinno być jako lib api z którego tylko odpalam metody
const sendStylesToApi = (styles:StyleDef) => {
  host.postMessage( { command: "setStyles", styles }, targetOrigin );
}

const WidgetWindow = ({component}:{component: ComponentType}) => {
  
  const [maximized, setMaximized] = useState(false);
  
  // TODO: To powinno wyleciec do dedykowanego providera chyba - powinien być jakiś może AppProvider/Widgetprovider?
  const mobile = useGetMediaQueryFromLoader();

  useEffect(() => {

    if ( maximized ) {
      sendStylesToApi(getMaximizedStyles(mobile));
    } else {
      sendStylesToApi(getMinimizedStyles(mobile));
    }

  }, [mobile, maximized]);
  
  const maximize = () => {
    setMaximized(true);
  };

  const minimize = () => {
    setMaximized(false);
  };
  
    return (
      <>
        {maximized === false && <DefaultChatIcon onClick={maximize}/>}
        {maximized === true && <Widget onMinimize={minimize} component={component} />}
      </>
    );
    
};

export default WidgetWindow;
