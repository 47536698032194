import {SyntheticEvent, MouseEventHandler} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowMinimize} from "@fortawesome/free-solid-svg-icons";
//import MainDropdown from "./MainDropdown";
import "./Header.scss";
//import {useChat} from "@chatscope/use-chat";

export const Header = ({title = "", onMinimize = (evt: SyntheticEvent) => undefined}:{title?:string, onMinimize?: MouseEventHandler}) => {

//  const {activeConversation} = useChat();
  
  return (
    <div className="d-flex flex-row justify-content-between align-items-stretch cw-header">
  
      <div className="d-flex align-items-center">
        {/*<MainDropdown/>*/}
      </div>
  
      <div className="flex-grow-1 d-flex align-items-center justify-content-center font-weight-bold">
        {title} {/*{activeConversation?.id}*/}
      </div>
  
      <button type="button" className="btn d-flex align-items-center p-0 cw-c-pointer cw-header__minimize-btn"
              onClick={onMinimize}>
        <FontAwesomeIcon icon={faWindowMinimize}/>
      </button>
    </div>
  );
}
    

export default Header;