import type {Store} from "redux";
import {push} from "connected-react-router";

export const onEvent = (store:Store) => (actionsEvent:any) => {
  
  actionsEvent.actions.forEach( (action:any) => {
    
    const {type} = action;
    if ( type === "showLeaveMessageForm" ) {
      store.dispatch(push("/message-form"));
    }
    
  });
  
}