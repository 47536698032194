import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(XHR).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  ns: [
    "App"
  ], // Add more namespaces here
  defaultNS: "app",
  // Change this to switch logging
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    //useSuspense: false
  },
  // For available options see at https://github.com/i18next/i18next-xhr-backend
  backend: {
    loadPath: "locales/{{lng}}/{{ns}}.json"
  },

});

export default i18n;
