import {useEffect, useState} from "react";

// This must be identical to mediaQueries in the loader configuration
// Warning! Watch out for whitespace! event returns query with whitespace regarding of query configured in the loader
const mobileQueries = [
  "(min-width: 576px)",
  "(min-width: 768px)",
  "(min-width: 992px)",
  "(min-width: 1200px)",
  "(min-width: 1400px)",
];

const host = window.parent;
const targetOrigin = "*";

export const useGetMediaQueryFromLoader = () => {
  
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {

    const onMessage = (evt:MessageEvent) => {
      // TODO: Filter sender domain
      
      const evtData = evt.data;
      const name = evtData?.name;
      const data = evtData?.data;
      if ( name === "media-query-list-change" && data) {
        
        if ( data.media ) {
          
          let isMobile;
          const idx = mobileQueries.indexOf(data.media); 
          
          if ( idx === 0 ) {
            isMobile = true;
          } else if ( idx === 1 && data.matches === false ) {
            isMobile = true;
          } else {
            isMobile = false;
          }
          
          setIsMobile(isMobile);
          
        }
        
      }
    }

    window.addEventListener("message", onMessage);

    // Send request for initial mediaQuery state
    host.postMessage( { command: "get-media-query", data: mobileQueries[1] }, targetOrigin )
    
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);
  
  return isMobile;
  
}